import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb forta olor a llexiu que presenta un capell de fins a 7 cm de diàmetre, campanulat, amb un mamelló que cada vegada es fa més evident quan el capell s’obre. Les nombroses làmines són blanques, higròfanes amb moltes lamel·les. Les espores són blanques en massa, el·líptico-ovoides, de 8,5-11 x 6-6,7 micres. El peu, llarg i més ample a la base on presenta pelets blancs, és estriat de dalt a baix i freqüentment un poc tort.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      